// stylelint-disable declaration-no-important

.bd-masthead {
  position: relative;
  padding: 3rem ($grid-gutter-width / 2);
  background: linear-gradient(to right bottom, lighten($bd-purple-light, 16%) 50%, #fff 50%);

  h1 {
    @include font-size(4rem);
    line-height: 1;
  }

  .lead {
    @include font-size(1.5rem);
    font-weight: 400;
    color: $gray-700;
  }

  .btn {
    padding: .8rem 2rem;
    font-weight: 600;
    @include font-size(1.25rem);
  }

  .carbonad {
    margin-top: 0 !important;
    margin-bottom: -3rem !important;
  }

  @include media-breakpoint-up(sm) {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .carbonad {
      margin-bottom: 0 !important;
    }
  }

  @include media-breakpoint-up(md) {
    .carbonad {
      margin-top: 3rem !important;
    }
  }
}

.masthead-followup {
  .bd-clipboard { display: none; }

  h2 {
    @include font-size(2.5rem);
  }

  .highlight {
    @include border-radius(.5rem);

    pre::-webkit-scrollbar {
      display: none;
    }

    pre code {
      display: inline-block;
      white-space: pre;

      &::before {
        display: none;
      }
    }
  }
}

.masthead-followup-icon {
  padding: .75rem;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, .2), rgba(255, 255, 255, .01));
  @include border-radius(.75rem);
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .1);
}

.masthead-followup-svg {
  filter: drop-shadow(0 1px 0 rgba(0, 0, 0, .125));
}
